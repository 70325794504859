import React from "react";
import { FormattedMessage, Link } from "gatsby-plugin-intl";

import Layout from "../components/layout";

const Resource = (props) => {
  const { title } =
    props.pageContext.data[props.pageContext.language].frontmatter;

  const html = props.pageContext.data[props.pageContext.language].html;

  return (
    <Layout>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"/resources"}>
              {" "}
              <FormattedMessage
                id="resources.back_to_resources"
                defaultMessage="Resources"
              />
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {title}
          </li>
        </ol>
      </nav>
      <h1>{title}</h1>
      <article dangerouslySetInnerHTML={{ __html: html }}></article>
    </Layout>
  );
};

export default Resource;
